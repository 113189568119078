import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Note from '../../components/note';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>

    <h1 {...{
      "id": "table-of-contents"
    }}>{`Table of contents`}</h1>
    <Note>
  This guide is a WIP
    </Note>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      